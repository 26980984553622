







import { Component, Mixins, Watch, Vue, Ref } from 'vue-property-decorator'
import ButtonBack from '@/components/molecules/quiz/ButtonBack.vue'
import ListUnit from '@/components/organisms/quiz/ListUnit.vue'
import UnitQuiz from '@/mixins/quiz/UnitQuiz'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'
import NoticeQuiz from '@/mixins/quiz/NoticeQuiz'
import { TypeClickEntrace, Unit } from '@/types/quiz/units'
import ModalNotice from '@/components/organisms/quiz/ModalNotice.vue'
import ModalShowBadge from '@/components/organisms/quiz/ModalShowBadge.vue'

@Component({
  components: {
    ListUnit,
    ButtonBack,
    ModalNotice,
    ModalShowBadge,
  },
})
export default class Units extends Mixins(UnitQuiz, BaseQuiz, NoticeQuiz) {
  @Ref() modalNoticeRef!: ModalNotice

  @Ref() modalBadgeRef!: ModalShowBadge

  private notice: any = {}

  private level = ''
  private parentId = ''
  private data: Unit[] = []

  @Watch('$route', { deep: true })
  watchRoute() {
    this.level = this?.$route?.params?.level as string
    this.parentId = this?.$route?.params?.parentId as string
  }

  @Watch('level')
  async watchLevel() {
    if (!this.level) {
      this.data = []
    }
    this.data = await this.getUnits(this.level, this.parentId)
  }

  private async loadNotice() {
    const notices = await this.loadNotifications()
    this.notice = notices?.[0]
    if (this.notice && this.getNoticeMarked() !== this.notice.id) {
      this.modalNoticeRef.show()
      this.markedNotice(this.notice.id)
    } else this.openNext()
  }

  private async entranceCurriculum(type: TypeClickEntrace, unit?: Unit, nameBtn?: string) {
    let params: any = {}
    switch (type) {
      case TypeClickEntrace.Random:
        params = {
          scopeLevel: this.parentLevel[this.level],
          drill_type: TypeClickEntrace.Random,
          scope_id: this.parentId,
        }
        break
      case TypeClickEntrace.Bookmark:
        params = {
          scopeLevel: this.parentLevel[this.level],
          drill_type: TypeClickEntrace.Bookmark,
          scope_id: this.parentId,
        }
        break
      case TypeClickEntrace.Unit:
        params = {
          scopeLevel: this.level,
          drill_type: TypeClickEntrace.Random,
          scope_id: unit?.id || 0,
        }
        break
      default:
        params = {}
    }
    const title = (unit?.name ? `【${unit?.name}】から` : nameBtn) as string
    this.startLearning(params, title)
      .then(() => {
        this.push('/quiz/question', title)
      })
      .catch(() => null)
  }

  private get badges() {
    return this.$store.getters['badges/listBadge']
  }
  private async openNext() {
    if (this.badges.length > 0) {
      this.modalBadgeRef.show()
    }
  }

  private async mounted() {
    this.level = this?.$route?.params?.level as string
    this.parentId = this?.$route?.params?.parentId as string
    this.loadNotice()
  }
}
