





































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonBorder from '@/components/atoms/quiz/ButtonBorder.vue'
import ButtonBack from '@/components/molecules/quiz/ButtonBack.vue'
import { ChallengeProcess, LevelUnits, TypeClickEntrace, Unit } from '@/types/quiz/units'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'

@Component({
  components: {
    ButtonBorder,
    ButtonBack,
  },
})
export default class ListUnit extends Mixins(BaseQuiz) {
  @Prop({ default: [] })
  private units?: Unit[]

  @Prop()
  private level!: LevelUnits

  @Prop()
  private clickEntrace!: (type: TypeClickEntrace, unit?: Unit) => void

  private isReload = false

  private reload() {
    this.isReload = true
    location.reload()
  }

  private get showBtnMarkBook() {
    const book = this.getBookInfo()
    return book.level === this.level
  }

  private get textRandom() {
    const book = this.getBookInfo()
    return book.level === this.level ? 'すべての問題からランダム出題' : 'このジャンルからランダム出題'
  }

  private getChallenge(unit: Unit) {
    switch (unit.status) {
      case ChallengeProcess.FULL:
        return 'CLEAR!'
      case ChallengeProcess.HALF:
        return '学習中'
      case ChallengeProcess.ZERO:
      default:
        return null
    }
  }

  private get nameRandom() {
    const book = this.getBookInfo()
    const nameUnitParent = this.$route.query.preTitle
    return book.level === this.level ? 'すべての問題からランダム' : `【${nameUnitParent}】からランダム`
  }

  private getColor(unit: Unit) {
    switch (unit.status) {
      case ChallengeProcess.FULL:
        return 'green'
      case ChallengeProcess.HALF:
        return 'orange'
      case ChallengeProcess.ZERO:
      default:
        return 'gray'
    }
  }

  private transition(unit: Unit) {
    if (unit.level === LevelUnits.SMALL) {
      return this.clickEntrace(TypeClickEntrace.Unit, unit)
    }
    this.push(
      `/quiz/units/${unit.level === LevelUnits.LARGE ? LevelUnits.MEDIUM : LevelUnits.SMALL}/${unit.id}`,
      unit.name
    )
  }
}
