










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ButtonBorder extends Vue {
  @Prop()
  size?: 's' | 'm' | 'l'

  @Prop()
  prefix?: string

  @Prop()
  subfix?: string

  @Prop()
  disabled?: 's' | 'm' | 'l'

  @Prop()
  color?: 'blue' | 'orange' | 'green' | 'white' | 'gray' | 'blue-light'

  get classObject() {
    const { color = 'blue', size = 'm', disabled } = this
    return `button-border__size-${size} button-border__color-${disabled ? 'disabled' : color}`
  }

  private clickEvent(key: Event): void {
    this.$emit('click', key)
  }
}
